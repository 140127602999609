<template>
  <div class="m-2">
    <bread-crumbs :items='items'/>
    
    <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
      <v-row>
        <v-col
          cols="12"
          md="10"
          sm="12">
          <b-input-group class="mt-3">
            <b-form-input placeholder="Start Date" style="background:#eee" type="date" v-model="sdate"></b-form-input>
            <b-form-input placeholder="End Date" style="background:#eee" type="date" v-model="edate"></b-form-input>
            <b-form-input :placeholder="lang.mobile"  style="background:#eee;" v-model="mobile"></b-form-input>
            <b-form-input :placeholder="lang.plate_number" style="background:#eee;" v-model="plate_number"></b-form-input>
            <v-select
                item-text="text"
                item-value="value"
                v-model="selected"
                :items="xstatus"
                :placeholder="lang.status"
            ></v-select>
            <b-input-group-append style="border-left:1px solid #CCC">
              <b-button variant="light"
               style="background: darkblue !important;font-size:14px;color:#FFF;padding:7px 22px" @click="getCurrentCards()">{{lang.search}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </v-col>
        <v-col cols="12" md="2" sm="4">
          <b-input-group class="mt-3">
            <b-input-group-append style="border-left:1px solid #CCC">
              <v-btn class="_mybtn1"
                v-b-toggle.sidebar-1
                :style="`background: green !important;color:#FFF !important;width:190px;margin-`+lang.algin+`:9px`"
              >{{lang.add_maint_request}}</v-btn>
              <v-btn
                v-b-toggle.add_invoice
                v-if="this.$store.state.viewhomeaddinvo"
                style="margin-left:10px;background: blue !important;color:#FFF !important;width:145px;margin-right:9px"
              >اضافة فاتورة</v-btn>
            </b-input-group-append>
          </b-input-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :style="`direction: `+lang.dir+`;`">
          <v-data-table
              :headers="headers"
              :items="tablerows"
              :search="search"
              :items-per-page="15"
              :page.sync="page"
              width="100%"
              id="MainTable"
              hide-default-footer
              @page-count="pageCount = $event"
              fixed-header
          >
            <template v-slot:item="row">
              <tr>
                <td style="width:100px">{{ row.item.cardid }}</td>
                <td nowrap>{{ row.item.customer }}</td>
                <td>{{ row.item.mobile }}</td>
                <td>{{ row.item.plate_number }}</td>
                <td>{{ row.item.car_model }}</td>
                <!-- <td>{{ row.item.car_color }}</td> -->
                <td nowrap>{{ row.item.date_in }}</td> 
                <td nowrap>{{ row.item.date_out }}</td>
                <td>{{ row.item.parts }}</td>
                <td>{{ row.item.ftotal }}</td>
                <td>{{ row.item.vat }}</td>
                <td>{{ row.item.paid }}</td>
                <td>{{ row.item.remaining }}</td>
                <td :style="`border:1px solid #fff;text-align:center;background:`+row.item._statusbg+`;color:`+row.item._statuscolor+` !important`">{{ row.item._status }}</td>
                <td>
                  <v-btn style="font-size:0.2em;background:#902626;width:100%;color:#FFF;height:25px !important;" @click="ViewItem(row.item)">{{lang.view}}</v-btn>
                  <!-- <i class="fas fa-pencil-alt ma-1" style="cursor:pointer;font-size:1.2rem;color:#222" @click="ViewItem(row.item)"></i> -->
                </td>
              </tr>
            </template>
            <template slot="body.append">
                    <tr class="black--text">
                        <th></th>
                        <th></th>
                        <th></th>
                        <!-- <th></th> -->
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th style="font-size:1.2em;background:#81da7a;text-align:center;">{{lang.totals}}</th>
                        <th style="font-size:1.2em;background:#81da7a;text-align:center;">{{ sumField('ftotal') }}</th>
                        <th style="font-size:1.2em;background:#81da7a;text-align:center;">{{ sumField('vat') }}</th>
                        <th style="font-size:1.2em;background:#81da7a;text-align:center;">{{ sumField('paid') }}</th>
                        <th style="font-size:1.2em;background:#81da7a;text-align:center;">{{ sumField('remaining') }}</th>
                        <th></th>
                        <th></th>
                    </tr>
            </template>
          </v-data-table>
          
        </v-col>
        <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">

          <v-container>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                value="10"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-row>
    <CreateNewCard />
    <addInvoice />
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import CreateNewCard from "@/components/CreateNewCard.vue"
  import addInvoice from '@/components/addInvoice.vue'
  import BreadCrumbs from '@/components/breadCrumbs.vue'
  export default Vue.extend({
    name: 'Home',

    components: {
      CreateNewCard,addInvoice,
        BreadCrumbs
    },
    data() {
      return {
        selected:1,
        plate_number:'',
        dBgcolor:'',
        search: '',
        drow: false,
        page: 1,
        pageCount:0,
        sdate: '',
        edate: '',
        mobile: '',
        excel_fields:{},
        
        tablerows: [
          
        ],
        ishome: true,
      }
    },
    created() {
      // this.$router.push({path: '/invoicelist'})
      this.getCurrentCards();
      this.getExcelHead();
    },
    methods: {
       
      getExcelHead(){
        const head = this.headers;
        
        // for(var i=0;i< head.length;i++){
        //   const x = head[i].text;
        //   const y = head[i].value;
        //   this.excel_fields[x] = y;
        //   console.log("xx",this.excel_fields[x]);
        // }
        
      },
      gotoDaily(){
        this.$router.push({path: '/reports/daily-reports'})
      },
      PrintPage(){
        window.print();
      },
      sumField(key) {
        let x = this.tablerows.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
        x = +x *100;
        x = (Math.round(x)/100);
        if(x != 0) return x;
        else return 0;
      },
      ViewItem(item){
        if(item.cardid == null || typeof item.cardid === 'undefined'){
          return false;
        }else{
          this.$router.push({ path: '/view-card/'+item.cardid });
        }
      },
      getCurrentCards(){
        let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
        let post = new FormData();
        post.append('type','getCardslist'); 
        post.append('auth',cook);
        post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
        let data = {
          datatype: 'all',
          sdate: this.sdate,
          edate: this.edate,
          mobile: this.mobile,
          plate_number: this.plate_number,
        };
        post.append('data[datatype]',data.datatype);
        post.append('data[sdate]',data.sdate);
        post.append('data[edate]',data.edate);
        post.append('data[mobile]',data.mobile);
        post.append('data[plate_number]',this.plate_number);
        post.append('data[status]',this.selected);
        this.tablerows = [];
        axios.post(
          this.$store.state.SAMCOTEC.r_path,
          post
        ).then((response) => {
          const res = response.data;
          console.log(res);
          this.tablerows = res.results.data;
        })
      },
      ExportToExcel(){
        //
      },
      
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function() {
          return [
            {
              text: this.lang.cardid,
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
            },
            {
              text: this.lang.customer_name,
              align: 'center',
              filterable: false,
              value: 'customer',
              sortable: false,
            },
            {
              text: this.lang.mobile,
              align: 'center',
              filterable: false,
              value: 'mobile',
              sortable: false,
            },
            {
              text: this.lang.plate_number,
              align: 'center',
              filterable: false,
              value: 'plate_number',
              sortable: false,
            },
            {
              text: this.lang.car_model,
              align: 'center',
              filterable: false,
              value: 'car_model',
              sortable: false,
            },
            // {
            //   text: this.lang.car_color,
            //   align: 'center',
            //   filterable: false,
            //   value: 'car_color',
            //   sortable: false,
            // },
            {
              text: this.lang.received,
              align: 'center',
              filterable: false,
              value: 'sdate',
              sortable: false,
            },
            {
              text: this.lang.delivered,
              align: 'center',
              filterable: false,
              value: 'ddate',
              sortable: false,
            },
            {
              text: this.lang.spare_partes,
              align: 'center',
              filterable: false,
              value: 'parts',
              sortable: false,
            },
            {
              text: this.lang.total,
              align: 'center',
              filterable: false,
              value: 'ftotal',
              sortable: false,
            },
            {
              text: this.lang.vat,
              align: 'center',
              filterable: false,
              value: 'vat',
              sortable: false,
            },
            {
              text: this.lang.paid,
              align: 'center',
              filterable: false,
              value: 'paid',
              sortable: false,
            },
            {
              text: this.lang.remain,
              align: 'center',
              filterable: false,
              value: 'remaining',
              sortable: false,
            },
            {
              text: this.lang.status,
              align: 'center',
              filterable: false,
              value: '_status',
              sortable: false,
            },
            {
              text: this.lang.action,
              align: 'center',
              filterable: false,
              value: '',
              sortable: false,
            }
        ]
      },
      items:function() {
          let title = this.$store.state.hometitle;
          const lang = this.$cookies.get(this.$store.state.LangCooki);
          if(lang == "en"){
            title = this.$store.state.hometitle_en;
          }
          return {
            text: title,
            disabled: true,
            href: '/home',
          }
      },
      xstatus: function() {
        return [
          {
              value: 1,
              text: this.lang.inprogress
          },
          {
              value: 3,
              text: this.lang.closed
          },
          {
              value: 2,
              text: this.lang.not_invoiced
          },
        ]
      }
    },
  })
</script>
<style>
.v-data-table-header__icon{
  display:none !important;
}
.outsearch{
  color:green;
  border:1px solid #ccc;
}
thead th{
  background: #27467b !important;
  color:#FFF !important;
  border:1px solid #eee !important;
  font-size:12px;
  white-space: nowrap;
}
thead th a , thead th i, thead th i a{
  color:#FFF !important;
  white-space: nowrap;
}
tbody td{
  font-size:12px;
  color:#000 !important;
  white-space: nowrap;
  border:1px solid #ccc;
}
.v-breadcrumbs{
  padding:12px !important;
}
.v-tab--active{
  background:#ccc;
  color:#FFF;
}
.v-tabs-slider-wrapper a{
  color:#FFF !important;
}
tfoot th{
  border:1px solid #ccc;
}
.close svg {
    width: 74px !important;
    height: 34px !important;
    color: #FFF;
    background: red;
}
header button.close {
    width: 24px !important;
    height: 24px !important;
}
input{
  box-shadow: none !important;
}
button:active,button:focus{
  box-shadow: none !important;
}
._mybtn{
  font-size:.8rem;
  border:0px solid #fff;
}
._mybtn2{
  font-size:.8rem;
  background:rgb(15, 212, 230) !important;
  border:0px solid #000;
}
._mybtn4{
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
._mybtn3{
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
._mybtn1{
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
td,th{
      height: 30px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 10px !important;
}
</style>


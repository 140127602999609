import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Loading from '../views/Loading.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Loading',
    component: Loading
  }, 
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: () => import( '@/views/CustomerList.vue')
  // },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: () => import( '@/views/invoicesList.vue')
  // },
  {
    path: '/invoicelist',
    name: 'InvoiceList',
    component: () => import( '@/views/invoicesList.vue')
  },
  {
    path: '/view-invoice/:id',
    name: 'viewInvoice',
    component: () => import( '@/views/viewInvoice.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/view-card/:cardid',
    name: 'ViewCard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ViewCard.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/Logout.vue')
  },
  {
    path: '/vat-reports',
    name: 'vat-reports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/vat-reports.vue')
  },
  {
    path: '/settings/system-settings',
    name: 'SYSTEM SETTINGS',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/system-settings.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="add_invoice" aria-labelledby="sidebar-no-header-title" no-header backdrop width="90rem" :style="`direction:`+$parent.lang.ldir" right :title="$parent.lang.add_invoice" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{$parent.lang.add_invoice}}</span>
        </div>
        <div @click="hide" id="HideMe" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{$parent.lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row v-if="$store.state.licenseType.cars || $store.state.licenseType.bill">
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="$parent.lang.invoiceno"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        :label="$parent.lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        @change="getName()"
                        >{{ customer.customerid }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        :label="$parent.lang.mobile"
                        v-model="customer.mobile"
                        size="10"
                        id="CusMobile"
                        @change="getName()"
                        >{{ customer.mobile }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="8" xs="12">
                    <v-text-field
                        :label="$parent.lang.customer_name"
                        v-model="customer.full_name"
                        id="CusMobile"
                        >{{ customer.full_name }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="customer.cctype"
                        :items="customer.ctype"
                        :label="$parent.lang.customer_type"
                        @change="changeCtype()"
                        ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="$store.state.licenseType.parts">
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="$parent.lang.invoiceno"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        :label="$parent.lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        @change="getName()"
                        >{{ customer.customerid }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        :label="$parent.lang.mobile"
                        v-model="customer.mobile"
                        :rules="fieldRules"
                        size="10"
                        id="CusMobile"
                        @change="getName()"
                        >{{ customer.mobile }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="8" xs="12">
                    <v-text-field
                        :label="$parent.lang.customer_name"
                        v-model="customer.full_name"
                        :rules="fieldRules"
                        >{{ customer.full_name }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        :label="$parent.lang.model"
                        v-model="car.model"
                        :rules="fieldRules"
                        >{{ car.model }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="customer.cctype"
                        :items="customer.ctype"
                        :label="$parent.lang.customer_type"
                        @change="changeCtype()"
                        ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="showcamp">
                <v-col cols="12" md="2" sm="12" xs="12" v-if="$store.state.licenseType.showproject">
                    <v-text-field
                        :label="$parent.lang.projectname"
                        v-model="company.projectname"
                        >{{ company.projectname }}</v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                    <v-text-field
                        :label="$parent.lang.company_name"
                        v-model="company.fullname"
                        >{{ company.fullname }}</v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                    <v-text-field
                        :label="$parent.lang.comapny_address"
                        v-model="company.address"
                        >{{ company.address }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <v-text-field
                        :label="$parent.lang.company_vatid"
                        v-model="company.vatid"
                        >{{ company.fullname }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="$parent.lang.building_no"
                        v-model="customer.building_no"
                        >{{ company.building_no }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="$parent.lang.street_name"
                        v-model="company.street_name"
                        >{{ company.street_name }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="$parent.lang.branch_number"
                        v-model="company.branch_number"
                        >{{ company.branch_number }}</v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <v-text-field
                        :label="$parent.lang.district"
                        v-model="company.district"
                        >{{ company.district }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="$parent.lang.zipcode"
                        v-model="company.zipcode"
                        >{{ company.zipcode }}</v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <v-text-field
                        :label="$parent.lang.city"
                        v-model="company.city"
                        >{{ company.city }}</v-text-field>
                </v-col>
            </v-row>
            <v-row  v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">
                <v-col cols="12" md="2" sm="12">
                    <v-text-field
                        :label="$parent.lang.plate_number"
                        v-model="car.plate_number" 
                    >{{ car.plate_number }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <v-text-field
                        :label="$parent.lang.car_model"
                        v-model="car.model"
                        >{{ car.model }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12">
                    <v-text-field
                        :label="$parent.lang.car_year"
                        v-model="car.made_year"
                        >{{ car.made_year }}</v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12">
                    <v-text-field
                        :label="$parent.lang.car_color"
                        v-model="car.color"
                        >{{ car.color }}</v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="invoice.invtype"
                        :items="invoice.invtypes"
                        :label="$parent.lang.invoice_type"
                        @change="InvType()"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="invoice.vtype"
                        :items="invoice.vattype"
                        :label="$parent.lang.vat_type"
                        @change="changePtype()"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="invoice.paytype"
                        :items="invoice.paytypes"
                        :label="$parent.lang.payment_method"
                        @change="changePtype()"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12">
                    <v-select
                        item-text="text"
                        item-value="value"
                        v-model="invoice.userid"
                        :items="invoice.users"
                        :label="$parent.lang.saler_name"
                        @change="changePtype()"
                        ></v-select>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12">
                    <v-text-field
                            v-model="invoice.date"
                            :label="$parent.lang.invoice_date"
                            prepend-icon="mdi-calendar"
                            readonly
                        >{{ invoice.date }}</v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="8" xs="12">
                    <v-textarea
                        :label="$parent.lang.invoice_notes"
                        v-model="invoice.notes"
                        >{{ invoice.notes }}</v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-window>{{$parent.lang.invoice_details}}</v-window>
                <v-simple-table striped hover style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>{{$parent.lang.item_code}}</th>
                                <th style="width:35%" class="text-center">{{$parent.lang.description}}</th>
                                <th class="text-center">{{$parent.lang.qtty}}</th>
                                <th class="text-center">{{$parent.lang.item_price}}</th>
                                <th class="text-center">{{$parent.lang.total}}</th>
                                <th class="text-center">{{$parent.lang.vat}}</th>
                                <th class="text-center">{{$parent.lang.ftotal}}</th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody id="tablerow">
                            <tr v-for="(item,index) in tbrows" :key="index">
                                <td>{{ item.itemcode }}</td>
                                <td>{{ item.item_name }}</td>
                                <td>{{ item.qty }}</td>
                                <td>{{ item.item_price }}</td>
                                <td>{{ item.total }}</td>
                                <td>{{ item.vat }}</td>
                                <td>{{ item.ftotal }}</td>
                                <td>
                                    <b-button type="button" variant="danger" style="width:65px" @click="removeItem(index)">حذف</b-button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <v-text-field
                                        v-model="newrow.itemcode"
                                        @change="getItemname()"
                                        >
                                    </v-text-field>
                                </td>
                                <td>
                                    <v-combobox
                                        v-model="newrow.item_name"
                                        :items="itemsworkdes"
                                        small-chips
                                        
                                    ></v-combobox>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="newrow.qty"
                                        @change="calcKit()"
                                        type="number"
                                        id="newitemqty"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="newrow.item_price"
                                        @change="calcKit()"
                                        type="number"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="newrow.total"
                                        @change="calcKit()"
                                        type="number"
                                        readonly
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="newrow.vat"
                                        @change="calcKit()"
                                        type="number"
                                        readonly
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="newrow.ftotal"
                                        @change="calcKit()"
                                        type="number"
                                        readonly
                                    ></v-text-field>
                                </td>
                                <td>
                                    <b-button type="button" class="btn-sm" variant="success" @click="addNewRow()">{{$parent.lang.add}}</b-button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"> % {{$parent.lang.discount}}</th>
                                <th class="text-center">
                                    <v-text-field
                                        v-model="invoice.discount"
                                        @change="calcAll()"
                                        type="number"
                                    ></v-text-field>
                                </th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center">{{$parent.lang.total}}</th>
                                <th class="text-center">{{ full_total }}</th>
                                <th class="text-center">{{ full_vat }}</th>
                                <th class="text-center">{{ full_ftotal }}</th>
                                <th class="text-center"></th>
                            </tr>
                        </tfoot>
                        
                    </template>
                </v-simple-table>
            </v-row>
            <v-row>
                <v-col cols="12" md="4" sm="6" xs="12">
                    <v-text-field
                        :label="$parent.lang.cash_payment"
                        v-if="invoice.paytype == 1 || invoice.paytype == 3"
                        v-model="invoice.payment.cash"
                        type="number"
                        >{{ invoice.payment.cash }}</v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6" xs="12">
                    <v-text-field
                        v-if="invoice.paytype == 2 || invoice.paytype == 3"
                        :label="$parent.lang.span_payment"
                        v-model="invoice.payment.span"
                        type="number"
                        >{{ invoice.payment.span }}</v-text-field>
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <b-button type="button" @click="addInvoice()" id="addBTN" variant="success" class="ma-2 btn-sm" style="width:130px;">{{$parent.lang.create_invoice}}</b-button>
        </div>
      
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>

<script>
import axios from 'axios'
export default{
    props:[
        'custom','prods','cardid'
    ],
    data() {
        return {
            payby: 1,
            itemsworkdes:[
                
            ],
            car: {
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
            },
            modal: false,
            full_total: 0,
            full_vat: 0,
            full_ftotal: 0,
            showcamp: false,
            company:{
                fullname: '',
                vatid: '',
                projectname: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
            },
            customer: {
                id:0,
                mobile: '',
                full_name: '',
                customerid: '',
                cctype: 1,
                ctype: [
                    {
                        text: this.$parent.lang.individual_client,
                        value: 1,
                    },
                    {
                        text: this.$parent.lang.company_client,
                        value: 2,
                    }
                ]
            },
            invoice:{
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: 0,
                    span: 0
                },
                billid: 1,
                discount:0 ,
                t_discount:0 ,
                vtype:2,
                vattype: [
                    {
                        text: this.$parent.lang.prices_include_vat,
                        value: 3,
                    },
                    {
                        text: this.$parent.lang.prices_exclusive_vat,
                        value: 2,
                    },
                    {
                        text: this.$parent.lang.prices_without_vat,
                        value: 1,
                    },
                ],
                invtype:2,
                invtypes: [
                    {
                        text: this.$parent.lang.none_paid_invoice,
                        value: 2,
                    },
                    {
                        text: this.$parent.lang.paid_invoice,
                        value: 1,
                    },
                ],
                paytype: 2,
                paytypes: [
                    {text: this.$parent.lang.cash_payment, value: 1},
                    {text: this.$parent.lang.span_payment, value: 2},
                    {text: this.$parent.lang.bank_payment, value: 3},
                    {text: this.$parent.lang.multi_payments, value: 3},
                ],
                userid: 0,
                users: [

                ],
                notes: '',
            },
            fieldRules: [
                v => !!v || this.$parent.lang.required_field,
            ],
            tbrows:[
            ],
            newrow:{
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: ''
            },
        }
    },
    created() {
        this.getInvSettings();
        setTimeout(() => {
            this.getInfo(); 
        }, 2000);
    },
    methods: {
        getItemname(){
            if(this.newrow.itemcode == ''){
                return false;
            }
            const post = new FormData();
            post.append("type" , "getItemBycode"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemcode]',this.newrow.itemcode)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data.results.data.results;
                if(res.length != 0){
                    this.newrow.item_name = res[0].name;
                    this.newrow.item_price = res[0].price;
                }
                this.calcKit();
                document.getElementById('newitemqty').focus();
            })
            // .then(() => {
            //     // newrow = {
            //     //     itemcode: '',
            //     //     item_name: '',
            //     //     qty: 1,
            //     //     item_price: 0,
            //     //     total: 0,
            //     //     vat: 0,
            //     //     ftotal: 0
            //     // }
            // })
        },
        getInfo(){
            
            if(this.custom == null) {return false;}
            this.customer.mobile = this.custom.mobile;
            this.company.fullname = this.custom.company;
            this.company.vatid = this.custom.vatid;
            this.getName();
        },
        resetInvoice(){
            this.payby = 1;
            this.itemsworkdes = [
                
            ];
            this.car = {
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
            };
            this.modal = false;
            this.full_total =  0;
            this.full_vat =  0;
            this.full_ftotal =  0;
            this.showcamp =  false;
            this.company = {
                fullname: '',
                vatid: '',
                address: '',
            };
            this.customer.id = 0;
            this.customer.mobile = '';
            this.customer.full_name = '';
            this.customer.customerid = '';
            this.customer.cctype = '';
            this.invoice.payment.cash = 0;
            this.invoice.payment.span = 0;
            this.invoice.payment.discount = 0;
            this.invoice.payment.vtype = 0;
            this.invoice.payment.t_discount = 0;
            
            this.tbrows = [
            ];
            this.newrow = {
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: 0,
                vat: 0,
                ftotal: 0
            }
        },
        addInvoice(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(!this.$refs.forinv.validate()){
                return false;
            }
            if(this.customer.mobile == '') {
                this.customer.mobile = 1234567890;
            }
            if(this.customer.full_name == '') {
                this.customer.full_name = this.$parent.lang.individual_client;
            }
            if(this.tbrows.length == 0){
                this.$snotify.error(this.$parent.lang.you_can_not_add_empty_invoice, this.$parent.lang.add_invoice, {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }
           if(this.customer.cctype == 2 && this.company.fullname == ''){
               this.$snotify.error(this.$parent.lang.company_ifnormation_required, this.$parent.lang.add_invoice, {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
           }
            document.getElementById('addBTN').disabled = true;
            const post = new FormData();
            post.append("type" , "addInvoice"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }
            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            for(let i = 0; i < this.tbrows.length;i++){
                for(const [key, value] of Object.entries(this.tbrows[i])){
                    post.append('data[tbrows]['+i+']['+key+']]',value);
                }
            }
            for(const [key, value] of Object.entries(this.car)){
                post.append('data[car]['+key+']]',value);
            }
            post.append("data[inovice][date]",this.invoice.date);
            post.append("data[inovice][itemcode]",0);
            post.append("data[inovice][payment][cash]",this.invoice.payment.cash);
            post.append("data[inovice][payment][span]",this.invoice.payment.span);
            post.append("data[inovice][billid]",this.invoice.billid);
            post.append("data[inovice][vtype]",this.invoice.vtype);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][paytype]",this.invoice.paytype);
            post.append("data[inovice][userid]",this.invoice.userid);
            post.append("data[inovice][full_total]",this.full_total);
            post.append("data[inovice][full_vat]",this.full_vat);
            post.append("data[inovice][full_ftotal]",this.full_ftotal);
            post.append("data[inovice][t_discount]",this.invoice.t_discount);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][cash]",this.invoice.payment.cash);
            post.append("data[inovice][span]",this.invoice.payment.span);
            post.append("data[inovice][notes]",this.invoice.notes);
            post.append("data[inovice][projectname]",this.company.projectname);
            post.append("data[cardid]",this.cardid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => {
                console.log(resposne.data);
                document.getElementById('addBTN').disabled = false;
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                this.$snotify.success(this.$parent.lang.invoice_added, this.$parent.lang.add_invoice, {
                    timeout: 2000,
                    showProgressBar: true, 
                    closeOnClick: false,
                    pauseOnHover: true,
                    leftTop:"leftTop"
                });
                this.$parent.getCurrentCards();
                this.printPDF(resposne.data.results.data.results.newID);
                
            }).then(() => {
                this.resetInvoice();
                document.getElementById('HideMe').click();
            })

        },
        printInvo(id){
            window.open('../api/print.php?invid='+id,'_blank');
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?invid='+id;
        },
        changePtype(){
            let tbs = [];
             if(this.tbrows.length != 0){
                for(let i = 0; i < this.tbrows.length; i++){
                    let fftotal = parseFloat(this.tbrows[i].item_price) * parseFloat(this.tbrows[i].qty);
                    fftotal = this.$RoundNum(fftotal);
                    const vs = this.$calcVat(fftotal,this.invoice.vtype);
                    const itm = {
                        item_name: this.tbrows[i].item_name,
                        qty: this.tbrows[i].qty,
                        item_price: this.tbrows[i].item_price,
                        total: vs.tot,
                        vat: vs.vat,
                        ftotal: vs.ftot
                    }
                    tbs.push(itm);
                }
            }
            this.tbrows = tbs;
            this.calcAll();
            this.calcKit();
        },
        calcAll(){
            this.full_total = 0;
            this.full_vat = 0;
            this.full_ftotal = 0;
            if(this.tbrows.length != 0){
                for(let i = 0; i < this.tbrows.length; i++){
                    this.full_total = parseFloat(this.full_total) + parseFloat(this.tbrows[i].total);
                    this.full_vat = parseFloat(this.full_vat) + parseFloat(this.tbrows[i].vat);
                    this.full_ftotal = parseFloat(this.full_ftotal) + parseFloat(this.tbrows[i].ftotal);
                }
            }
            if(this.invoice.discount > 30){
                this.invoice.discount = 30;
            }
            if(this.invoice.discount != 0){
                this.invoice.t_discount = parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
                this.full_total = parseFloat(this.full_total) - parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
                this.full_vat = parseFloat(this.full_vat) - parseFloat(this.invoice.discount) * parseFloat(this.full_vat) / parseFloat(100);
                this.full_ftotal = parseFloat(this.full_ftotal) - parseFloat(this.invoice.discount) * parseFloat(this.full_ftotal) / parseFloat(100);
            }
            this.full_total = this.$RoundNum(this.full_total);
            this.full_vat = this.$RoundNum(this.full_vat);
            this.full_ftotal = this.$RoundNum(this.full_ftotal);
            if(this.invoice.paytype == 1 || this.invoice.paytype == 3){
                this.invoice.payment.cash = this.full_ftotal;
                this.invoice.payment.span = 0;
            }else if(this.invoice.paytype == 2){
                this.invoice.payment.cash = 0;
                this.invoice.payment.span = this.full_ftotal;
            }
            if(this.invoice.invtype == 2){
                this.invoice.payment.cash = 0;
                this.invoice.payment.span = 0;
            }
        },
        InvType () {
            if(this.invoice.invtype == 1){
                if(this.paytype == 1){
                    this.invoice.payment.cash = this.full_ftotal;
                    this.payment.span = 0;
                }else{
                    this.invoice.payment.span = this.full_ftotal;
                    this.invoice.payment.cash = 0;
                }
            }else{
                this.invoice.payment.span = 0;
                this.invoice.payment.cash = 0;
            }
        },
        removeItem(item){
            this.tbrows.splice(item, 1);
            this.calcAll()
        },
        calcKit() {
            const vt = this.invoice.vtype;
            const price = +this.newrow.item_price * +this.newrow.qty;
            const vat = this.$calcVat(price,vt);
            this.newrow.total = this.$RoundNum(vat.tot);
            this.newrow.ftotal = this.$RoundNum(vat.ftot);
            this.newrow.vat = this.$RoundNum(vat.vat);
        },
        addNewRow() {
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.newrow.item_name == ''){
                this.$snotify.error(this.$parent.lang.can_not_add_item_withoud_discription, this.$parent.lang.add_invoice, {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }
            if(this.newrow.qty == '' || this.newrow.qty == 0){
                this.$snotify.error(this.$parent.lang.item_qtty_must_be_more_than_zero, this.$parent.lang.add_invoice, {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }
            if(!this.$store.state.zeroPrice){

                if(this.newrow.item_price == '' || this.newrow.item_price == 0){
                    this.$snotify.error(this.$parent.lang.item_price_must_be_more_than_zero, this.$parent.lang.add_invoice, {
                            timeout: 2000,
                            showProgressBar: true,
                            closeOnClick: false,
                            pauseOnHover: true,
                            leftTop:"leftTop"
                        });
                    return false;
                }
            }
            this.tbrows.push(this.newrow);
            this.newrow = {
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: 0,
                total: 0,
                vat: 0,
                ftotal: 0
            }
            this.calcAll()
        },
        changeCtype() {
            if(this.customer.cctype == 2){
                this.showcamp = true;
            }else{
                this.showcamp = false;
            }
        },
        getInvSettings() {
            const post = new FormData();
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        if(res.results.data.users.length != 0){
                            for(let i= 0; i < res.results.data.users.length; i++){
                                this.invoice.users.push({
                                    text: res.results.data.users[i].full_name,
                                    value: res.results.data.users[i].id
                                })
                            }
                        }
                        if(res.results.data.desk.length != 0){
                            this.itemsworkdes = [];
                            this.allProducts = res.results.data;
                            for(let i= 0; i < res.results.data.desk.length; i++){
                                this.itemsworkdes.push(res.results.data.desk[i].title);
                            }
                        }
                        if(res.results.data.products.length != 0){
                            this.allProducts = res.results.data;
                            for(let i= 0; i < res.results.data.products.length; i++){
                                this.itemsworkdes.push(res.results.data.products[i].itemcode + ' - ' + res.results.data.products[i].name);
                            }
                        }
                    }
                }
            )
        },
        getName(){
            const post = new FormData();
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            post.append('data[customerid]',this.customer.customerid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    console.log(res);
                    if(res.error.number == 200){
                        this.customer.id = res.results.data['id'];
                        this.customer.mobile = res.results.data['mobile'];
                        this.customer.full_name = res.results.data['full_name'];
                        this.customer.customerid = res.results.data['customerid'];
                        this.company.fullname = res.results.data['company_name'];
                        this.company.vatid = res.results.data['company_vatid'];
                    }
                }
            )
        }
    },
}
</script>
<template>
<div></div>
</template>

<script>
import axios from 'axios'
export default {
  name: "CheckLogin.vue",
  data: () => ({
    s: {},
    code: null,
    key: null,
    cook: null,
  }),
  methods:{
    getIP()
    {
      const url = this.$SAMCOTEC.ip_url;
      axios.get(url).then((response) => {
        //console.log(response.data.ip);
        this.$SAMCOTEC.ip = response.data.ip
        this.loginCheck()
        setInterval(() => {this.loginCheck();},10000);
      })
    },
    loginCheck(){
      this.s = this.$SAMCOTEC;
      this.code = this.$cookies.get(this.$COOKIEPhase);
      
      this.key = this.$COOKIEPhase;
      this.cook = this.$cookies;
      const code = this.code;
      const s = this.s;
      const key = this.key;
      const cook = this.cook;
      if(code != '' && code !== null){
        const post = new FormData();
        post.append('type','check');
        post.append('auth',code.toString());
        post.append('data',"[]");
        let path = '';
        if(typeof s.r_path !== 'undefined' && s.rpath != ''){
          path = s.r_path;
        }
        axios.post(
          path,
          post
        ).then(
          (response) => {
            const res = response.data;
            
            if(typeof res.error !== 'undefined' 
              && res.error != '' && res.error != null
              && typeof res.error.number !== 'undefined'
              && res.error.number != '' && res.error.number != null){
                if(res.error.number == 200){
                  const hash = res.results.data.ID+'||'+res.results.hash+'||'+this.$SAMCOTEC.ip+'||'+res.time;
                  cook.set(key.toString(),hash);
                  const codeE = response.data.results.data.ID+'||'+response.data.results.hash+'||'+this.$SAMCOTEC.ip+'||'+response.data.results.time+'||'+this.$cookies.get(this.$store.state.LangCooki);
                  this.full_name = response.data.results.data.full_name;
                  this.$cookies.set(this.$COOKIEPhase.toString(),codeE,86400);
                  s.connected = true;
                  this.connected = true;
                  document.getElementById('HelloID').innerHTML = this.full_name;
                  this.$SAMCOTEC.connected = true;
                  this.$emit('updateParent', true)
                  this.$store.state.group_id = response.data.results.data.group_id;
                }else{
                  cook.delete(s.COOCKIE);
                  this.$SAMCOTEC.connected = false;
                }
              }
          }
        )
      }else{
        this.$SAMCOTEC.connected = false;
        cook.remove(s.COOCKIE);
      }
    }
  },
  created() {
    this.getIP();
    //console.log(this.$cookies.get(this.$COOKIEPhase));
  }
}
</script>

<style scoped>

</style>
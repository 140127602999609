<template>
  <v-form ref="formcard">
    <b-sidebar style="direction:ltr" backdrop v-show="!isExist" right id="sidebar-1" aria-labelledby="sidebar-no-header-title" no-header title="إضافة بطاقة" width="95%" shadow>
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:140px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{$parent.lang.add_maint_request}}</span>
        </div>
        <div @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm closeme">
          <span>{{$parent.lang.close}}</span>
      </div>
    </div>
      
      <v-divider style="margin-top:10px"></v-divider>
      <div style="background: #FFF;border:1px solid #ccc" class="ma-3 pa-1">

        <v-container style="direction:rtl;">
            
            <v-row style="border:1px solid #ccc;direction:rtl;">
                <v-col cols="12" md="5" sm="12" class="d-flex align-stretch mb-6 pa-1">
                    <v-row> 
                        <v-col cols="12" md="7" sm="12">
                            <div class="pa-1" style="width:100%;">
                                <h6>{{$parent.lang.main_info}}</h6>
                            </div>
                            <v-row class="pa-1" style="width:100%;">
                              <v-col
                                cols="12"
                                sm="12"
                                md="12"
                              >
                                <v-dialog
                                  ref="dialog"
                                  v-model="modal"
                                  :return-value.sync="date"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="date"
                                      :label="$parent.lang.received_date"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="date"
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="modal = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.dialog.save(date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-col>
                                <v-col
                                cols="12"
                                sm="12"
                                md="12"
                              >
                                <v-dialog
                                  ref="dialog1"
                                  v-model="modal1"
                                  :return-value.sync="date1"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="date1"
                                      :label="$parent.lang.delivery_date"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="date1"
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="modal1 = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.dialog1.save(date1)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-dialog>
                              </v-col>
                                <v-progress-linear
                                  v-model="card_status"
                                  height="25"
                                  style="direction:rtl;display:none"
                                >
                                  <strong>{{ Math.ceil(card_status) }}%</strong>
                                </v-progress-linear>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="5" sm="12" style="background:#eee;">
                            <div class="pa-1" style="width:100%;">
                                <h6>{{$parent.lang.customer_info}}</h6>
                            </div>
                            <v-row class="pa-1" style="width:100$;">
                                <v-col cols="12" md="12" sm="12">
                                  <v-text-field
                                    :label="$parent.lang.mobile"
                                    v-model="customer.mobile"
                                    :rules="fieldRules"
                                    id="CusMobile"
                                    @change="getName()"
                                  >{{ customer.mobile }}</v-text-field>
                                </v-col>
                                
                                <v-col cols="12" md="12" sm="12">
                                  <v-text-field
                                    :label="$parent.lang.customer_name"
                                    v-model="customer.full_name"
                                    id="CusName"
                                    :rules="fieldRules"
                                  >{{ customer.full_name }}</v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="d-flex align-stretch mb-6 pa-1">
                    <v-row>
                        <v-col cols="12" md="12" sm="12" style="background:#eee;">
                            <div class="pa-1" style="width:100%;">
                                <h6>{{$parent.lang.company_info}}</h6>
                            </div>
                            <v-row class="pa-1" style="width:100$;">
                                <v-col cols="12" md="12" sm="12">
                                  <v-text-field
                                    :label="$parent.lang.company_name"
                                    v-model="customer.company_name"
                                  >{{ customer.company_name }}</v-text-field>
                                </v-col>
                                
                                <v-col cols="12" md="12" sm="12">
                                  <v-text-field
                                    :label="$parent.lang.company_vatid"
                                    v-model="customer.vatid"
                                  >{{ customer.full_name }}</v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="d-flex align-stretch mb-6 pa-1">
                  <v-row>
                      <v-col cols="12" md="12" sm="12">
                          <div class="pa-1" style="width:100%;">
                                <h6>{{$parent.lang.car_info}}</h6>
                                <!-- <v-btn-toggle class="pa-1" style="color:#FFF;float:left;margin-top:-30px;background:green">
                                اضافة سيارة
                                </v-btn-toggle> --> 
                                 
                            </div>
                          <v-row class="pa-1">
                            <v-col cols="12" md="6" sm="12">
                                <v-text-field
                                  :label="$parent.lang.plate_number"
                                  v-model="car.plate_number"
                                  id="carPlate"
                                  @change="getCar()"
                                >{{ car.plate_number }}</v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" sm="12">
                                <v-text-field
                                  :label="$parent.lang.car_model"
                                  v-model="car.model"
                                  id="carModel"
                                >{{ car.model }}</v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" sm="12">
                                <v-text-field
                                  :label="$parent.lang.car_color"
                                  v-model="car.color"
                                  id="carColor"
                                >{{ car.color }}</v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" sm="12">
                                <v-text-field
                                  :label="$parent.lang.car_year"
                                  v-model="car.made_year"
                                  id="carYear"
                                  type="number"
                                >{{ car.made_year }}</v-text-field>
                            </v-col>
                          </v-row>
                      </v-col>  
                  </v-row>
                </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" sm="12">
                <CVAdditionalRow :getData="getClicked" :lang="$parent.lang" />
                <v-row>
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="text-center" colspan="2">{{$parent.lang.received_status}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in scrachNoties" :key="index">
                          <td class="text-center">{{ item }}</td>
                          <td class="text-center" style="width:50px;">
                            <v-btn style="background:red;color:#FFF;width:50px;padding:1px !important" @click="remScr(index)">{{$parent.lang.delete}}</v-btn>
                          </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          <v-text-field 
                            v-model="scrachnotes" 
                            :label="$parent.lang.status">{{ scrachnotes }}</v-text-field>
                        </td>
                        <td class="text-center" style="width:50px;">
                          <v-btn style="background:green;color:#FFF;width:50px;padding:1px !important" @click="addScr()">{{$parent.lang.add}}</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-row>
              </v-col>
              <v-col cols="12" md="8" sm="12">
                  <div style="margin:5px;" class="rowTitle">
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-center">{{$parent.lang.work_description}}</th>
                          <th class="text-center">{{$parent.lang.worker}}</th>
                          <th class="text-center">{{$parent.lang.work_price}}</th>
                          <th class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item,index) in DesWrk" :key="index">
                            <td class="text-center" style="white-space:normal !important;">{{item.work}}</td>
                            <td class="text-center">{{item.name}}</td>
                            <td class="text-center">{{item.price}}</td>
                            <td class="text-center">
                                <v-btn @click="delWorks(index)" style="background:red;color:#FFF">حذف</v-btn>
                            </td>
                        </tr>
                        <tr style="background:lightblue">
                            <td class="center"></td>
                            <td class="center">{{$parent.lang.total}}</td>
                            <td class="center">{{servicePrice}}</td>
                            <td class="center"></td>
                        </tr>
                        <tr>
                          <td style="width:50%">
                            <v-combobox
                              v-model="wrkDes.work"
                              :items="carworkdes"
                              small-chips
                            ></v-combobox>
                          </td>
                          <td>
                            <v-select
                                item-text="text"
                                item-value="value"
                                v-model="wrkDes.empl"
                                :items="workers"
                              ></v-select>
                          </td>
                          <td>
                            <v-text-field
                                v-model="wrkDes.price"
                                type="number"
                            ></v-text-field>
                          </td>
                          <td>
                              <v-btn @click="addWroker()" style="background:green;color:#FFF">{{$parent.lang.add}}</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>   
                  <v-row>
                    <v-col cols="12" md="4" sm="12">
                      <v-select
                      item-text="text"
                      item-value="value"
                      v-model="vattype"
                      :items="vattypes"
                      :label="$parent.lang.vat_type"
                      @change="changePtype()"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                    <v-text-field
                        v-model="payment.paid"
                        :rules="fieldRules"
                        type="number"
                        :label="$parent.lang.advanced_payment"
                    >{{ payment.paid }}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                    <v-select
                    item-text="text"
                    item-value="value"
                    v-model="paytype"
                    :items="paytypes"
                    :label="$parent.lang.payment_method"
                    @change="changePtype()"
                    ></v-select>
                    </v-col>
                    
                    <v-col cols="12">
                    
                    </v-col>
                </v-row>
                  <div style="margin:5px;" class="rowTitle">
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab" @click="invsummary = !!invsummary">
                         {{$parent.lang.attachement}} <i class="fab fa-whatsapp" style="color:green;font-size:1.2em"></i></b-card-header>
                      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                          <b-card-text>
                            <b-button-group  style="width:100%;font-size:0.8rem">
                              <v-row>
                                <v-col cols="12" md="12" sm="12">
                                  <b-button-group  style="width:100%;font-size:0.8rem">
                                      <input
                                          type="file"
                                          multiple
                                          accept="image/*"
                                          label="$parent.lang.choose_file"
                                          style="width:98%;border:1px solid #ccc;padding:5px;"
                                          ref="attached"
                                          @change="selectFiles()"
                                          >
                                  </b-button-group>
                                </v-col>
                                <v-col cols="12" md="12" sm="12" style="direction:rtl">
                                    <span v-for="(attach,index) in attached" :key="index">
                                        <div class="pa-1 m-2" style="direction:rtl" >
                                            {{ attach.name }}
                                        <i @click.prevent="attached.splice(index,1)" class="fas fa-trash-alt m-2" style="float:left;"></i></div>
                                    </span>
                                </v-col>
                              </v-row>
                            </b-button-group>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                </div>
              </v-col>
            </v-row>
        </v-container>
      </div>
      
      <vue-snotify></vue-snotify>
      </template>

      <template #footer="" class="shadow">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <b-button type="button" @click="doAddCard()" id="mysubmit" variant="danger" class="ma-2 btn-sm" style="width:100px;">{{$parent.lang.save}}</b-button>
        </div>

      </template>
    </b-sidebar>
    </v-form>
</template> 


<script>
import CVAdditionalRow from '../components/CVAdditionalRow.vue'
// import addCustomer from '../components/addCustomer.vue'
// import addCar from '../components/addCar.vue'
import axios from 'axios'
export default {
  components:{
      CVAdditionalRow
  },
  data: () => ({
    vattype: 2,
    
    scrachNoties:[],
    wrkDes:{
        work: '',
        empl: '',
        price: '',
    },
    DesWrk:[
    ],
    paytype: 1,
    paycash: 0,
    payspan: 0,
    card_status: 10,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date1: '',
    modal: false,
    modal1: false,
    isExist: false,
    message_notify: '',
    carworkdes: [],
    showmsg: true,
    customer:{
      id: 0,
      mobile: '',
      full_name: '',
      company_name: '',
      vatid: '',
    },
    car:{
      plate_number: '',
      color: '',
      model: '',
      made_year: '',
      workdes: ''
    },
    payment:{
      ftotal:'',
      paid:''
    },
    clicked: [],
    workerssel:{
    },
    workers:[
      
    ],
    
    attached:[],
    scrachnotes: '',
    
    servicePrice: 0
  }),
  created() {
    this.getWorkers();
    this.getWorkerds();
    console.log(",.,",this.$parent.lang.cash_payment)
  },
  computed:{
    paytypes: function(){
      return [
        {
          text: this.$parent.lang.cash_payment,
          value: 1,
        },
        {
          text: this.$parent.lang.span_payment,
          value: 2,
        }
      ]
    },
    fieldRules: function() {
      return [
        v => !!v || this.$parent.lang.required_field,
      ]
    },
    vattypes: function() {
      return [
        {text: this.$parent.lang.prices_include_vat, value: 2},
        {text: this.$parent.lang.prices_exclusive_vat, value: 1},
        {text: this.$parent.lang.prices_without_vat, value: 3},
      ]
    }
  },
  methods:{
    getwrokerName(value){
        const workers = this.workers;
        for(let i = 0; i < workers.length;i++){
            if(workers[i].value == value){
                return workers[i].text;
            }
        }
    },
    collectServicePrice(){
        const workers = this.DesWrk;
        let ix = 0;
        for(let i = 0; i < workers.length;i++){
            ix = +ix + +workers[i].price
        }
        this.servicePrice = this.$RoundNum(ix);
    },
    delWorks(index){
        this.DesWrk.splice(index,1);
        this.collectServicePrice();
    },
    addWroker(){
        const workdek = this.wrkDes;
        if(workdek.empl == '' || workdek.work == '' || workdek.price == '' || workdek.price == 0){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            this.$snotify.error(this.$parent.lang.work_description_required,'',{
                timeout: 2000,
                showProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                leftTop:"leftTop"
            })
            return false;
        }
        this.DesWrk.push({
            empl: this.wrkDes.empl,
            work: this.wrkDes.work,
            price: this.wrkDes.price,
            name: this.getwrokerName(this.wrkDes.empl),
        });
        
        this.collectServicePrice();
        this.wrkDes.empl = '';
        this.wrkDes.work = '';
        this.wrkDes.price = '';
    },
    addScr(){
      this.scrachNoties.push(this.scrachnotes);
      this.scrachnotes = '';
    },
    remScr(index){
      this.scrachNoties.splice(index,1);
    },
    changePtype(){
      if(this.paytype == 1){
        this.paycash = this.payment.paid;
        this.payspan = 0
      }else if(this.paytype == 2){
        this.paycash = 0
        this.payspan = this.payment.paid;
      }
    },
    getClicked(clicked){
      this.clicked = clicked
    },
    getWorkerds(){
      this.carworkdes = [];
      const post = new FormData();
      post.append('type','getWorkerds');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[mobile]',this.customer.mobile);
      axios.post(
        this.$store.state.SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          if(res.error.number == 200){
            this.carworkdes = res.results.data;
          }
        }
      )
    },
    selectFiles(){
        const files = this.$refs.attached.files;
        this.attached = [...this.attached, ...files];
    },
    getWorkers(){
      this.workers = [];
      const post = new FormData();
      post.append('type','getWorkers');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[mobile]',this.customer.mobile);
      axios.post(
        this.$store.state.SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          if(res.error.number == 200){
            this.workers = res.results.data;
          }
        }
      )
    },
    getName(){
      
      const post = new FormData(); 
      post.append('type','customerInfo');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[mobile]',this.customer.mobile);
      axios.post(
        this.$store.state.SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          
          if(res.error.number == 200){
            this.customer.id = res.results.data['id'];
            this.customer.full_name = res.results.data['full_name'];
          }
        }
      )
    },
    getCar(){
      const post = new FormData();
      post.append('type','carInfo');
      post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append('data[plate_number]',this.car.plate_number);
      axios.post(
        this.$store.state.SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          if(res.error.number == 200){
            this.car = res.results.data;
          }
        }
      )
    },
    async doAddCard(){
      if(!this.$refs.formcard.validate()){
        return false;
      }
      if(this.DesWrk.length < 1){
        if (this.$snotify.notifications.length > 0) {
            this.$snotify.notifications.forEach(notification => {
                this.$snotify.remove(notification.id)
            });
        }
        this.$snotify.error(this.$parent.lang.work_description_required, '', {
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            leftTop:"leftTop"
        });
        return false;
      }
      document.getElementById('mysubmit').disabled = true;
      let _cardid = 0;
      const post = new FormData();
      const auth = this.$cookies.get(this.$store.state.COOKIEPhase)
      post.append("auth",auth);
      post.append("type",'addCard');
      post.append('data[customer][full_name]',this.customer.full_name);
      post.append('data[customer][mobile]',this.customer.mobile);
      post.append('data[customer][company_name]',this.customer.company_name);
      post.append('data[customer][vatid]',this.customer.vatid);
      post.append('data[car][plate_number]',this.car.plate_number);
      post.append('data[car][color]',this.car.color);
      post.append('data[car][model]',this.car.model);
      post.append('data[car][workdes]',this.car.workdes);
      post.append('data[car][made_year]',this.car.made_year);
      post.append('data[car][workers]',this.workerssel);
      post.append('data[car][clicked]',this.clicked);
      post.append('data[car][card_status]',this.card_status);
      post.append('data[payment][ftotal]',this.servicePrice);
      post.append('data[payment][paid]',this.payment.paid);
      post.append('data[in_date]',this.date);
      post.append('data[out_date]',this.date1);
      post.append('data[vattype]',this.vattype);
      post.append('data[company_name]',this.customer.company_name);
      post.append('data[company_vatid]',this.customer.vatid);

      const Wok = this.DesWrk;
      for(let ix = 0; ix < Wok.length;ix++){
          //--- workd desc
          post.append("data[card_item][service]["+ix+"][description]",Wok[ix].work);
          post.append("data[card_item][service]["+ix+"][qty]",1);
          post.append("data[card_item][service]["+ix+"][price]",Wok[ix].price);
          post.append("data[card_item][service]["+ix+"][cost]",0);
          //--- employees
          post.append("data[card_item][labor]["+ix+"][description]",Wok[ix].name);
          post.append("data[card_item][labor]["+ix+"][empid]",Wok[ix].empl);
          post.append("data[card_item][labor]["+ix+"][qty]",1);
          post.append("data[card_item][labor]["+ix+"][price]",0);
          post.append("data[card_item][labor]["+ix+"][cost]",0);
      }

      post.append("data[receipt][doc_type]","job_card");
      post.append("data[receipt][voutcherid]",0);
      post.append("data[receipt][received_from]",this.customer.full_name);
      post.append("data[receipt][mobile]",this.customer.mobile);
      post.append("data[receipt][type]",1);
      post.append("data[receipt][pay_reson]",0);
      post.append("data[receipt][vattype]",this.vattype);
      
      let vtype = 3;
      // if(this.vattype == 1) vtype = 2;
      // if(this.vattype == 2) vtype = 3;
      // if(this.vattype == 3) vtype = 1;
      const vc = this.$calcVat(this.payment.paid,3);
     
      post.append("data[receipt][total]",vc.tot);
      post.append("data[receipt][vat]",vc.vat);
      post.append("data[receipt][ftotal]",vc.ftot);
      post.append("data[receipt][paytype]",this.paytype);
      post.append("data[receipt][paycash]",this.paycash);
      post.append("data[receipt][payspan]",this.payspan);
      post.append("data[receipt][receipt_date]",this.date);
      for(let i = 0;i<this.scrachNoties.length;i++){
        post.append("data[scrachnotes]["+i+"]",this.scrachNoties[i]);
      }
      for(let i=0;i<this.attached.length;i++){
          post.append('data[files]['+i+']',this.attached[i]);
      }
      axios.post(
        this.$store.state.SAMCOTEC.r_path,
        post
      ).then(
        (response) => {
          console.log(response.data);
          document.getElementById('mysubmit').disabled = false;
          _cardid = response.data.results.data.results[0].cardid;
          if(response.data.error.number == 200){
            document.getElementsByClassName('closeme')[0].click();
          }
        }
      ).then((response) => {
        if (this.$snotify.notifications.length > 0) {
            this.$snotify.notifications.forEach(notification => {
                this.$snotify.remove(notification.id)
            });
        }
        this.$snotify.success(this.$parent.lang.added_sucess, '', {
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            leftTop:"leftTop"
        });
        if(this.$parent.ishome)
          this.$parent.getCurrentCards();
      }).then((response) => {
          setTimeout(() => {
            this.$router.push({path: '/view-card/'+_cardid});
            document.getElementById('mysubmit').disabled = false;
          },500);
      })
    }
  }
}
</script>

<style>
h6{
  font-weight: bolder;
}
</style>
<template>
  <div id="pageHeaders" class="clearMe" style="z-index: 10;background:#28467c !important">
  <v-app-bar
      app
      color="accent-2"
      style="background:#28467c !important;"
      dense
      dark
  >
    <!-- <v-toolbar-title ><v-btn v-b-toggle.menu_bar style="background:none"><i class="fas fa-bars" style="margin-right:10px;font-size:16px"></i><span style="font-size:1.2em;margin-right:10px">القائمة</span></v-btn></v-toolbar-title> -->
    <v-toolbar-title>
      
      <a role="button" v-b-toggle.menu_bar style="background:none;text-decoration:none;">
        <i class="fas fa-bars" style="margin-right:10px;font-size:16px;color:#FFF;text-decoration:none;"></i>
          <span style="font-size:1.2em;margin-right:10px;color:#FFF;text-decoration:none;">{{lang.system_modules}}</span></a></v-toolbar-title>

    <v-spacer></v-spacer>
    <span style="direction: rtl">
      &nbsp;&nbsp;
      {{ $store.state.settings.site_title }}
      &nbsp;&nbsp;
    </span>
  </v-app-bar>
  <b-sidebar id="menu_bar" backdrop style="direction:ltr;background:#28467c !important" right shadow no-header>
      <div v-b-toggle.menu_bar style="float:right;margin-right:10px;margin-top:25px;cursor:pointer;font-size:.8em" class=" btn-sm">
          <i class="fas fa-arrow-circle-right" style="color:red;font-size:2em"></i>
      </div>
    <div style="margin:0;height:80px;border:1px solid blue;background-size: 400px !important;background:#fff url('../img/128-117-logo.png') center center no-repeat;text-align:center;">
     <img :src="`../img/samcotec-logo.png`">
    <div :style="`direction:`+lang.dir+`;background:#fff`">
      {{lang.welcometo}}: <span id="HelloID" style="color:red">{{ fullname }}</span>
    </div>
    <div style="padding:10px;width:100%;height:100%">
      <b-list-group>

        <b-list-group-item class="menuBarItem" to="/home" v-if="$store.state.licenseType.cars && $cookies.get($store.state.LangCooki) == 'ar'">
        {{$store.state.hometitle}}
        <v-avatar size="36px">
          <i class="fas fa-home"></i>
        </v-avatar>
        </b-list-group-item>
        <b-list-group-item class="menuBarItem" to="/home" v-if="$store.state.licenseType.cars && $cookies.get($store.state.LangCooki) == 'en'">
        {{$store.state.hometitle_en}}
        <v-avatar size="36px">
          <i class="fas fa-home"></i>
        </v-avatar>
        </b-list-group-item>
        <b-list-group-item class="menuBarItem" to="/invoicelist" v-if="!$store.state.licenseType.cars">
         {{lang.invoices}}
        <v-avatar size="36px">
          <i class="fas fa-home"></i>
        </v-avatar>
        </b-list-group-item>
        <b-list-group-item class="menuBarItem" to="/vat-reports">
         {{lang.vat_report}}
        <v-avatar size="36px">
          <i class="fas fa-home"></i>
        </v-avatar>
        </b-list-group-item>
        <!--         
        <b-list-group-item class="menuBarItem" to="/settings/system-settings"
        v-if="groupid == 1"
        >{{lang.settings}}
        <v-avatar size="36px">
          <i class="fas fa-cog"></i>
        </v-avatar>
        </b-list-group-item> -->
        
        <b-list-group-item class="menuBarItem" to="/logout">{{lang.logout}}
        <v-avatar size="36px">
          <i class="fas fa-sign-out-alt"></i>
        </v-avatar>
        </b-list-group-item>
      </b-list-group>
    </div>
    </div>
  </b-sidebar>
  </div>
</template>

<script>

import axios from 'axios';
import Vue from 'vue'

export default Vue.extend({
  name: 'Home',

  components: {
  },
  
  data: () => ({
    drow: true,
    fullname: null,
    settings: {

    },
    publicPath: window.location.origin+process.env.BASE_URL,
    lang: {}
  }),
  methods:{
    setLang(){
        if(this.$cookies.get(this.$store.state.LangCooki) != null){
          this.$store.state.deflang = this.$cookies.get(this.$store.state.LangCooki);
          
        }
        
    },
    Logout(){
        this.$router.push({ path: "/logout"})
    },
    getSettings(){
      const post = new FormData();
      post.append("type" , 'getSettings');
      post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
      post.append("data[all]",'all');
      axios.post(
        this.$SAMCOTEC.r_path, post
      ).then(
        (response) => {
          const res = response.data;
          this.settings = res.results.data;
          this.$store.state.settings = res.results.data;
        }
      )
    },
    langg(){
          
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
            if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              this.lang = this.$store.state.lang.en;
            }else{
              this.lang = this.$store.state.lang.ar;
            }
          }
          else if(this.$store.state.deflang == 'en'){
            this.lang = this.$store.state.lang.en;
          }else{
            this.lang = this.$store.state.lang.ar;
          }
      },
  },
  created() {
    this.getSettings();
    setInterval(() => {
      if(this.$route.name == "Login"){
        document.getElementById('pageHeaders').classList.add('clearMe');
      }else{
        document.getElementById('pageHeaders').classList.remove('clearMe');
      }
    },1000),

    setInterval(() => {this.langg();},2000)
  },
  computed: {
      groupid: function() {
       // console.log("asdfa",this.$store.state.group_id);
        return this.$store.state.group_id;
      },
      
  },
});
</script>

<style>
#pageHeaders .b-sidebar > .b-sidebar-body{
  background:#28467c;
}
.clearMe{
  display:none;
}
.menuBarItem{
  text-align: right !important;
  color:#000 !important;
  font-weight: 1000;
  font-size: 1.3em;
  background:#ffb75f !important;
  border-bottom: 2px solid #FFF !important;
}
.menuBarItem:last-child{
  border-bottom: 1px solid #ccc !important;
}
</style>